import React, {useState} from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/Footer"
import Lightbox from "../../components/lightbox"

export default function Component(props) {
  
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const baseUrl="https://res.cloudinary.com/revivecars/image/upload/h_180/";
  const items = [];
  const images = [];
  var i;
  for (i=0; i < props.data.allCloudinaryMedia.nodes.length; i++) {
        const count = i;
        items.push( <div className="col image_detail" key={i}>
                     <img src={baseUrl + props.data.allCloudinaryMedia.nodes[i].public_id + ".jpg"} onClick ={() => {setPhotoIndex(count); setIsOpen(true); }} className="img-fluid" alt=""/>
                  </div>)
       images.push(props.data.allCloudinaryMedia.nodes[i].secure_url);          
     }


  return (
 <Layout>
  <Seo title={props.data.carsJson.title} />
  <section>
 <div className="blog py-5 gallery_view">
  <div className="container">
    <div className="row row-cols-1">
      <div className="col">
        <div className="card">
          <img src={props.data.carsJson.image} className="card-img-top cursor" style={{width: "100%"}} onClick={() => setIsOpen(true)} className="hover-shadow cursor" alt={props.data.carsJson.title}/>
         {isOpen && 
          <Lightbox images={images} 
                    isOpen={isOpen} 
                    photoIndex={photoIndex}  
                    onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex - 1) % images.length)
                    }
                    onMoveNextRequest={() => 
                      setPhotoIndex ((photoIndex + 1) % images.length)
                    }
                    onCloseRequest={() => setIsOpen(false)}/>
         }
          <div className="col-lg-8 my-md-5 mx-auto">
           <div className="card-body">
              <h2 className="card-tile text-center">{props.data.carsJson.title}</h2>
              <span className="card-date text-center">{props.data.carsJson.price}</span>
              <div className="row">
                <div className="col">
                  <table className="table">
                      <tbody>
                        <tr>
                          <td>Aasta</td>
                          <td>{props.data.carsJson.year}</td>
                        </tr>                        
                        <tr>
                          <td>Läbisõit</td>
                          <td>{props.data.carsJson.odo}</td>
                        </tr>
                         <tr>
                          <td>Võimsus</td>
                          <td>{props.data.carsJson.power}</td>
                        </tr>  
                         <tr>
                          <td>Mootor</td>
                          <td>{props.data.carsJson.engine}</td>
                        </tr>                                             
                        <tr>
                          <td>Ülevaatus</td>
                          <td>{props.data.carsJson.mot}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div className="col">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Kütus</td>
                          <td>{props.data.carsJson.fuel}</td>
                        </tr>
                        <tr>
                          <td>Värv</td>
                          <td>{props.data.carsJson.color}</td>
                        </tr>
                         <tr>
                          <td>Vedu</td>
                          <td>{props.data.carsJson.traction}</td>
                        </tr>  
                         <tr>
                          <td>Käigukast</td>
                          <td>{props.data.carsJson.gearbox}</td>
                        </tr>                                             
                        <tr>
                          <td>VIN</td>
                          <td>{props.data.carsJson.vin}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
                <p>{props.data.carsJson.body}</p>
                <p>{props.data.carsJson.body2}</p>
                <p>{props.data.carsJson.body3}</p>
                <p>{props.data.carsJson.body4}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 g-2 mt-3 text-center">
        {items}
      </div>
   </div>
   </div>
   <Footer/>
 </section>
 </Layout>)
}

export const query = graphql`
 query($id: String) {
  allCarsJson {
    edges {
      node {
        id
      }
    }
  }
  carsJson(id: {eq: $id}) {
    body
    body2
    body3
    body4
    color
    engine
    fuel
    gearbox
    mot
    odo
    power
    price
    title
    vin
    traction
    year
    image
    tag
  }
  allCloudinaryMedia(filter: {tags: {eq: $id}}) {
    nodes {
      public_id
      secure_url
    }
  }
}
`