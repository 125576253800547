import React, { Component } from "react"
import PropTypes from "prop-types"
import closeImg from "../images/svg/close.svg"
import leftImg from "../images/svg/left-arrow.svg"
import rightImg from "../images/svg/right-arrow.svg"


export default class Lightbox extends Component {

  static propTypes = {
    images: PropTypes.array.isRequired,
  }
  render() {
    const { images, photoIndex, onCloseRequest, onMovePrevRequest, onMoveNextRequest } = this.props

    const imgStyle = [];
    for (let i=0; i < images.length; i++) {
        let display = {display: "none"};
        if (i == photoIndex){
            display =  {display: "block"};
        }
        imgStyle.push( <div className="mySlides" key={i} style={display}>
                        <div className="numbertext">{i+1 +"/" + images.length}</div>
                        <img src={images[i]} style={{width: "100%"}}/>
                    </div>)        
     }

    return (
      <div id="myModal" className="modal" style={{display: "block"}} >
            <span className="close cursor" ><i><img src={closeImg} onClick={() => onCloseRequest()} alt=""/></i></span>
            <div className="modal-content">
                 {imgStyle}
              <a className="prev" ><img src={leftImg} onClick={() => onMovePrevRequest()} alt=""/></a>
              <a className="next" ><img src={rightImg} onClick={() => onMoveNextRequest()} alt=""/></a>

              <div className="caption-container">
                <p id="caption"></p>
              </div>
            </div>
     </div>
    )
  }
}